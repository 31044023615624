module.exports = [{
      plugin: require('/srv/www/softroot.ru/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":800,"quality":100},
    },{
      plugin: require('/srv/www/softroot.ru/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-54129986-5"},
    },{
      plugin: require('/srv/www/softroot.ru/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
